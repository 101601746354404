.home-background {
    background-image: url("./assets/home-page-landing.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.background-overlay {
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-start {
    padding-top: 100px;
}

.login-background {
    background-image: url("./assets/login-page.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg-primary {
    background-color: var(--blue) !important;
}

.bg-secondary {
    background-color: var(--light-blue) !important;
}

.bg-tertiary {
    background-color: var(--white) !important;
}

.text-primary {
    color: var(--white) !important;
}

.text-secondary {
    color: var(--blue) !important;
}

.text-tertiary {
    color: var(--grey) !important;
}

.img-fluid {
    width: 100%;
}

.button-submit {
    width: 125px;
}

.button-login {
    width: 100px;
}

.counselor-picture {
    width: 250px;
    height: 250px;
}

.footer-links {
    text-decoration: none;
    color: var(--blue);
}

.h1 {
    font-size: 2.5rem !important;
}

.truncate-description {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.h2 {
    font-size: 2.25rem !important;
}

.h3 {
    font-size: 2rem !important;
}

.h4 {
    font-size: 1.75rem !important;
}

.h5 {
    font-size: 1.5rem !important;
}

.main-container {
    padding: 0px 6rem;
}

.cursor {
    cursor: pointer;
}

.actual-price {
    text-decoration: line-through;
    color: #D3D3D3;
}

.toolbar-logo {
    width: 75px;
    margin: 8px 0px;
}

.toolbar-box-shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.footer-social-media {
    padding: 8px 15px;
    border-radius: 50%;
    background-color: var(--blue);
    color: var(--white);
    font-size: 24px;
}

.who-image-1 {
    width: 300px;
    height: 100%;
    margin-top: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/who-we-are-1.webp");
}

.who-image-2 {
    width: 200px;
    height: 100%;
    width: 300px;
    margin-top: -200px;
    background-position:bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/who-we-are-2.webp");
}

.linkedin-icon {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--light-blue);
    font-size: 32px;
}

.session-icons {
    color: #D3D3D3;
}

.mission-image {
    width: 600px;
}

.contact-image {
    height: 400px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 600px;
    margin: auto;
}

.session-card:hover {
    background-color: var(--light-blue);
    transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 900px) {
    .main-container {
        padding: 0px 2rem;
    }

    .h1 {
        font-size: 2.25rem !important;
    }

    .h2 {
        font-size: 2rem !important;
    }

    .h3 {
        font-size: 1.75rem !important;
    }

    .h4 {
        font-size: 1.5rem !important;
    }

    .h5 {
        font-size: 1.25rem !important;
    }
}

@media screen and (max-width: 400px) {
    .main-container {
        padding: 0px 1rem;
    }

    .h1 {
        font-size: 2rem !important;
    }

    .h2 {
        font-size: 1.75rem !important;
    }

    .h3 {
        font-size: 1.5rem !important;
    }

    .h4 {
        font-size: 1.25rem !important;
    }

    .h5 {
        font-size: 1rem !important;
    }

    .bg-transparent {
        background-color: var(--blue) !important;
    }

    .mission-image {
        width: 300px;
    }

    .who-image {
        width: 300px;
    }

    .contact-image {
        height: 200px;
        text-align: center;
        width: 300px;
        margin: auto;
    }
}